<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >Instructions for authors polices
            <b-icon-arrow-down></b-icon-arrow-down>
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <h5>Guidelines for Authors : </h5>
              <div>
                <p>
                  International Journal of Innovative Research in Engineering
                  (IJIRE) is (Online), Bi-monthly international journal for
                  publishing new ideas founded by academicians, educationists,
                  engineers, and corporate people. The research results and
                  fundamental advancement are all aspects of Innovative Research
                  in Advanced Engineering & Technology, various engineering
                  disciplines, etc. IJIRE is a scholarly open, accessible,
                  Double Blind Peer Review Journal that helps an academic person
                  and student community. IJIRE (International Journal of
                  Innovative Research Engineering) provides the academic
                  community and industry for submitting original research and
                  applications related to Various Engineering disciplines and
                  Advanced Technologies.
                </p>
                <p>
                  Papers for the journal's regular issues can be submitted
                  electronically at
                  <i>editorinchief@theijire.com</i>any time of year. Following
                  the final acceptance of the paper based on the detailed review
                  process, the paper will be published online immediately. The
                  assignment of the international journal paper to a specific
                  Volume / Issue of the Journal, on the other hand, will be
                  handled by the Editorial Board later, and the author will be
                  notified accordingly. Time-bound Special Calls for Papers will
                  be announced for Theme-Based Special Issues, which will only
                  apply to that specific issue.
                </p>
                <p>
                  The submission of an international journal paper implies that
                  the work described has not been previously published and needs
                  to be considered for publication elsewhere. The paper should
                  be submitted with the approval of all authors.
                </p>
                <p>
                  If a paper is finally accepted, the authorities where the work
                  had been carried out shall be responsible for not publishing
                  the work elsewhere in the same form. Once submitted for
                  consideration in IJIRE, the paper can only be withdrawn if the
                  same is finally rejected.
                </p>
              </div>

              <div>
                <h5>Manuscript Submission : </h5>
                <p>
                  The submission of a manuscript implies that the work described
                  has not been previously published; that it is not currently
                  under consideration for International Journal of Innovative
                  Research publication elsewhere; and that all co-authors, if
                  any, have approved its publication, as well as by the
                  responsible authorities or explicitly at the institute where
                  the work was carried out. If compensation claims are filed,
                  the distributor will not be held criminally responsible.
                </p>
              </div>
              <div>
                <h5>Permissions: </h5>
                <p>
                  Authors who wish to include previously published figures,
                  tables, or text excerpts must acquire permission from the
                  copyright owner(s) for both the print and web formats and
                  include proof of such permission when sending their papers.
                  Any material received without such proof will be presumed to
                  be the authors' work.
                </p>
              </div>
              <div>
                <h5>Author(s) be sure that: </h5>
                <p>
                  <b>Plagiarism Check: </b>High-quality plagiarism-detection
                  tools such as Crosscheck, iThenticate, and
                  plagiarism-detection software will be used to check for
                  resemblance, which should be at most 20%, including the
                  reference section. If referrals are excluded, it should be
                  less than 5%.
                </p>
                <p>
                  <b>Quality Check: </b>All papers presented should be
                  cutting-edge, goal-oriented, unique, and within the journal's
                  purview; they should also fall under engineering and
                  technology. "Overview/brief/ Introduction, Evaluation, Case
                  study/ Study, Survey, Approach, Comparison, Analysis,
                  Comparative Inquiry, Investigation" should not appear in the
                  document title.
                </p>
                <p>
                  <b>Paper Submission Criteria: </b>One author may submit a
                  maximum of 05 articles for the same volume/issue.
                </p>
                <p>
                  <b>Review Criteria: </b>This International Journal of
                  Innovative Research publication employs a double-blind review
                  method, which keeps the identities of the reviewer(s),
                  author(s), and vice versa hidden throughout the review
                  process. Every submitted paper is evaluated by three
                  reviewers, two from outside India. For approval or denial, the
                  evaluators' remarks should be appropriate. There should be a
                  two to three-week opening for it.
                </p>
                <p>
                  <b>Copyright: </b>All writers must send their work
                  electronically to the Journal of India.
                </p>
                <p>
                  <b>Template: </b>All approved articles must follow the IJIRE
                  Journal Template. It is necessary to ensure that the elements
                  of the document are satisfactory. Corrections can be made by
                  the author(s) in the final manuscript, but corrections cannot
                  be made after the final submission to the journal. The volume
                  number/issue number will be in the upper right corner of the
                  formatted page. In the event of a failure, the articles will
                  be removed from the journal's and publication house's
                  databases.
                </p>
                <p>
                  <b>Updating in the paper: </b>The final article is written
                  using the journal's form. The paper's contents are excellent
                  and acceptable. Corrections can be made by the author(s) in
                  the final manuscript, but corrections cannot be made after the
                  final submittal to the journal.
                </p>
                <p>
                  <b>Copyright Property: </b>After the International Journal of
                  Innovative Research publication, the conference proceeding
                  will be the property of the Editor-in-chief/IJIRE, India, and
                  the journal.
                </p>
                <p>
                  <b> Publication Fee: </b> Journal is free. Journal will not
                  take any fee/processing fee/handling fee/review fee.
                </p>
                <p>
                  <b>APC: </b> Article Processing Charge (APC) is not a
                  publication fee of the journal. A publication house will take
                  APC, 'IJIRE, India.' The journal does not take it. APC is
                  applicable per paper one time. It means you must pay for every
                  paper (article). The author can withdraw their paper at any
                  time before signing the copyright. There is no force. After
                  signing the copyright, the paper cannot withdraw under any
                  condition.
                </p>
                <p>
                  <b>Required Time for Endorsement: </b>It will take a minimum of
                  02 to 03 weeks.
                </p>
              </div>

              <div>
                <h5>Online Paper Submission: </h5>
                <p>
                  Prospective authors can submit full-text papers, including
                  abstracts, keywords, introductions, methodology, result
                  descriptions, tables, figures, and references. Full-text
                  papers (.doc, .pdf) may send by e-mail at
                  <i>editorinchief@ijire.com</i>
                </p>
                <p>
                  Paper Template of IJIRE (International Journal of Innovative
                  Research Engineering) available at website/downloads.
                </p>
                <p>
                  <b>Title page: </b>The title page should include the name(s)
                  of the author(s), a concise and informative title, the
                  affiliation(s) and address of the author(s), and the e-mail
                  address of the corresponding author.
                </p>
                <p>
                  <b>Abstract: </b>Kindly submit an abstract of 150-450 lines.
                  There should be no undefined acronyms or vague allusions in
                  the abstract.
                </p>
                <p>
                  <b>Keywords: </b>Author(s) should provide 4 to 6 keywords which
                  can be used for indexing purposes.
                </p>
                <p>
                  <b>Text formatting: </b>The paper setup must be in A4 format,
                  with the following margins: top 1.78 centimeters (about 0.7
                  in), bottom 1.78 cm, left 1.78 cm, and right 1.65 cm. After
                  the Author's Name, the document must be in one column with a
                  width of 8.59 cm and a spacing of 0.51 cm. Except for the
                  abstract, the entire paper must have Font Name Cambria, Font
                  Size 10, Line Spacing 1.05, Keywords (Index Term), Paper Tile,
                  References, Author Biography (on the last page of the paper,
                  maximum 400 words), All Headings, and Manuscript Information
                  (First Page, Bottom, left side). <br />The paper's title must
                  be in font size 24, bold, and with one space between each
                  word. The Author's name must be in the following formatting:
                  single line spacing, Letter Size 11, Strong, Before Spacing 0,
                  After Spacing 16. Kindly refrain from using the Author's email
                  address or mailing address instead of their name. The Draft
                  information must include the Author's email and mailing
                  address. Letter Size 9, Bold, Italic, and Single Line Spacing
                  is required for the abstract and keywords (Index Term). All
                  Major Headings must have a single line spacing, Upper Case,
                  Centered, Roman Numbering (I, II, III, etc.), and Before
                  Spacing 12 and After Spacing 6. title Case left 0.25 cm,
                  Serif, Letter Numbering (A, B, C, etc.), Before Spacing 6,
                  After Spacing 4, and Single Line Spacing are requirements for
                  all subheadings. The bottom, first page, and left side of the
                  manuscript must all have manuscript details in font size 8
                  with a single line spacing. The font size for references must
                  be 8, hanging 0.25, and single line spaced. Single line
                  spacing and Letter Size 8 are required for the author profile.
                </p>
                <p>
                  <b>Introduction: </b>State the objectives of the work and
                  provide an adequate background with a detailed literature
                  survey or a summary of the results.
                </p>
                <p>
                  <b>Theory/Calculation/Methodology: </b>The material mentioned
                  in the Introduction should be expanded upon in the Theory
                  Section, not repeated. A Calculation Part shows how a theory
                  foundation can be applied in practice.
                </p>
                <p><b>Result: </b>Results should be clear and concise.</p>
                <p>
                  <b>Discussion: </b>This section should explore the importance
                  of the results of the work, not repeat them. A combined
                  Results and Discussion section is often appropriate.
                </p>
                <p>
                  <b>Conclusion and Future Scope: </b>The study's main
                  conclusions may be presented in a short Conclusion Section. In
                  this section, the author(s) should also briefly discuss the
                  limitations of the International Journal of Innovative
                  Research and the Future Scope for improvement.
                </p>
                <p>
                  <b>Appendix: </b> If there are numerous supplements, they
                  should be labelled A, B, and so on. Appendix formulas and
                  equations should be numbered separately: Eq. (A.1), Eq. (A.2),
                  etc.; in a later annex, Eq. (B.1), and so on. Tables and
                  images should be labelled similarly: Table A.1, Figure A.1,
                  etc.
                </p>
                <p>
                  <b>Acknowledgement: </b> Authors may provide acknowledgments at
                  the end of the article before the references if desired. The
                  organizations/individuals who provided help during the
                  research (e.g., language help, writing assistance,
                  proofreading the article, sponsoring the research, etc.) may
                  be acknowledged here.
                </p>
                <p><b>Reference: </b></p>
                <p>
                  <b>Reference in text: </b>Please ensure that the reference list
                  contains all the sources mentioned in the text (and vice
                  versa). The sources in the reference list should be cited
                  according to the journal's and IJIRE's (International Journal
                  of Innovative Research Engineering) reference guidelines. Web
                  mentions should include the complete URL and the most recent
                  entry date. Any additional details (DOI, author names, times,
                  citation to a source journal, etc.) should also be provided.
                  Web sources may be included in the reference list or mentioned
                  independently (e.g., after the reference list) under a
                  distinct title.
                </p>
                <p>
                  <b>Style of reference: </b>Text- In accordance with the text,
                  indicate sources with a number(s) in square brackets. The
                  names of the writers can be mentioned, but the citation
                  number(s) must always be provided. As an example, consider
                  [3,6]. Barnaby and Jones [8] arrived at a different
                  conclusion....' List- Number the references in the list
                  (numbers in square brackets) in the sequence they occur in the
                  text. [1] G. O. Young, "Synthetic structure of industrial
                  polymers (Book format with paper title and editor)," in
                  Plastics, 2nd ed. vol. 3, J. Peters, Ed., McGraw-Hill, New
                  York, 1964, pp. 15-64.
                </p>
              </div>
              <div>
                <h5>Mathematical Formula: </h5>
                <p>
                  In the section of the required text, enter the calculation
                  using Equation Editor. Any formulae that must be referenced in
                  the text should be numbered sequentially.
                </p>
              </div>
              <div>
                <h5>Captions and Numbering figure and Tables: </h5>
                <p>
                  Check that each figure/table is labeled and captioned—
                  Captions should be supplied individually, not affixed to the
                  figure. A caption should include a short title and a summary
                  of the image. Figures and tables should be marked individually
                  but sequentially after they appear in the text.
                </p>
              </div>
              <div>
                <h5>Color illustrations: </h5>
                <p>
                  The publishing of color images online is free of charge. There
                  are no hidden fees.
                </p>
              </div>
              <div>
                <h5>Copyright: </h5>
                <p>
                  Copyright of all accepted papers will belong to IJIRE
                  (International Journal of Innovative Research Engineering),
                  and the author(s) must affirm that accepted papers for
                  International Journal of Innovative Research Publication in
                  IJIRE must not be re-published elsewhere without the written
                  consent of the editor-in-chief of Fifth Dimension Research
                  Group, India. To comply with this policy, authors must submit
                  a signed copy of the Copyright Transfer Form after acceptance
                  of their paper before the same is published. Copyright form
                  available at Website.
                </p>
              </div>
              <div>
                <h5>Copyright Policy: </h5>
                <p>
                  The authors emphasize that once approved, the work cannot be
                  withdrawn under any circumstances. Only the authors' work may
                  be published. If the Author's contribution has been
                  significantly changed, they may share it elsewhere with the
                  international journal publisher's previous written approval.
                  Except for excerpts from copyrighted works that may be
                  included with the permission of the copyright holder and
                  Author thereof, the Author warrants that their contribution is
                  original, that it contains no defamatory statements, and that
                  it does not infringe on any copyright, trademark, patent,
                  statutory right, or propriety right of others. The Author
                  authorizes and takes accountability for the release of this
                  content on behalf of any co-authors. In exchange for these
                  privileges.
                </p>
                <p>
                  All proprietary rights are other than copyrights, such as
                  patent rights.
                </p>
                <p>
                  The ability to incorporate all or a portion of this piece,
                  including the tables and figures, into other works so long as
                  the publisher is appropriately credited as the copyright
                  owner.
                </p>
                <p>
                  The right to make copies of this article for their use, but
                  not for sale.
                </p>
                <p>
                  Every author is accountable for making sure that papers are
                  written with ethical standards regarding plagiarism before
                  they are published to the International Journal of Innovative
                  Research publication. Kindly be aware that all entries undergo
                  a comprehensive plagiarism check. Suppose an effort at
                  plagiarism is discovered in a paper that has already been
                  published. In that case, the writers will be required to send
                  a written apology to the creators of the original work. Any
                  paper with blatant plagiarism will be immediately refused, and
                  the writers may face a two-year publication ban from journals
                  if their work is found to be plagiarized. If such a
                  circumstance occurs, the writers will be appropriately
                  notified.
                </p>
                <p>
                  This paper has been published in a different form elsewhere.
                </p>
                <p>
                  It will be submitted for publishing elsewhere after this
                  Publication accepts or rejects it.
                </p>
                <p>
                  Any dispute or related violence will be discussed and
                  considered only before the ‘Judiciary of Ariyalur’ in Tamil
                  Nadu, India.
                </p>
              </div>
              <div>
                <h5>Final Proof of the Paper: </h5>
                <p>
                  The associated author will receive one set of page proofs (in
                  the form of PDF files) via email, or an email with a link will
                  be sent so the writers can access the files directly. You can
                  annotate these PDF copies using Adobe Reader version 7 (or
                  later), accessible for free download at get.adobe.com/reader.
                  If writers want to avoid using the PDF comments feature, they
                  can list the changes and email them to
                  <i> editorinchief@theijire.com</i>Please include the line
                  number when listing corrections. If this is not feasible,
                  print out the proof and make any necessary corrections and
                  notes. Then, scan the documents that need to be corrected, and
                  send them back via email within 05 days. Please only use this
                  proof to ensure the text, tables, and figures are typeset,
                  edited, complete, and accurate. Only with previous consent
                  will significantly changes to a manuscript approved for
                  publication be considered.
                </p>
                <p>
                  If this is not feasible for any reason, then print out the
                  proof and make any necessary corrections and notes. Then, scan
                  the documents that need to be corrected, and send them back
                  via email within 05 days. Please only use this proof to ensure
                  that the text, tables, and figures are typeset, edited, full,
                  and accurate. Only with previous consent will significantly
                  changes to a manuscript that has been approved for
                  International Journal of Innovative Research Publication be
                  taken into account.
                </p>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>{{ text }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>  -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.btn-info {
  background-color: #72a9db;
}
.card {
  border: none;
}
</style>