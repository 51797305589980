<!-- <template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div class="row">
          <div class="col-9">
            <h5 class="blogTitle">
              HOW TO GET PUBLISHED IN A SCIENTIFIC JOURNAL :
            </h5>
            <div>
              <img
                height="450"
                width="500"
                src="../../../assets/Images/Features.png"
              />
            </div>
            <div class="row pt-5 m-2">
              <div class="pr-2">
                <h6>
                  <b-icon icon="bookmark-fill" variant="info"></b-icon> &nbsp;
                  How to get published in a scientific journal
                </h6>
              </div>
              <div class="pr-2">
                <h6>
                  <b-icon icon="calendar2" variant="danger"></b-icon
                  ><i>&nbsp;Sivaganesan14 September 21 , 5:41 PM</i>
                </h6>
              </div>
              <div>
                <h6>
                  <b-icon icon="person-fill" variant="success"></b-icon>
                  Sivaganesan
                </h6>
              </div>
            </div>
            <div class="row pr-2">
              <p>
                Every scientist aspires to get published in a scientific
                journal. The pressure of ‘publish or perish’ is something every
                researcher faces, and getting published in a high-quality
                journal helps move on to the next rung (or even skip a few
                rungs) up the ladder. The journey from finishing a scientific
                study and getting it into the public eye can be long and
                strenuous. According to an Elsevier report, 1.8 million authors
                submitted 1.3 million manuscripts in 2015, however, only 400,000
                (approximately 22%) of them were published. Nature publishes
                only 8% of the 200 papers it receives each week. So while you
                may be making significant progress in your research in the
                confines of your lab, telling the world about it involves quite
                a few barriers — and also requires years of patience.
              </p>
            </div>
          </div>
          <div class="col">
            <h5 class="recentPosts">Recent Posts : </h5>
            <img
              height="200"
              width="250"
              src="../../../assets/Images/GlobalPublisher.png"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.recentPosts {
  border-bottom: 1px dashed black;
}
.blogTitle {
  border-bottom: 1px dashed black;
}
</style> -->
<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div v-if="viewPaper" style="max-width: 1000px; margin: auto;">
          <div v-for="mainPageObj in mainPageObjList" :key="mainPageObj.id" class="main-paper-header p-2 ">
            <div class="article-header justify-content-between row">
              <h4 class="col-8 col-sm-9 col-md-10">{{ mainPageObj.title }}</h4>
              <div class="col-4 col-sm-3 col-md-2">

                <button type="submit" @click="openPaperDetails(mainPageObj.id)" class="viewArticlebtn p-2 float-right">
                  View Article
                </button>
              </div>
            </div>
            <div class="col">
              <!-- <img :src="mainPageObj.image" alt=""> -->
              <h6>{{ mainPageObj.title }}</h6>
              <p><b>Category By : </b>{{ mainPageObj.category }}</p>
              <p><b>Date : </b>{{ mainPageObj.created_at.split("T")[0] }}</p>
            </div>
          </div>

        </div>
        <div v-if="!viewPaper && paperpage" class="container" style="max-width: 1000px; margin: auto;"> 
          <div v-if="this.paperDetails==undefined">
            loading...
          </div>
          <div v-else>
            <h1>{{ this.paperDetails.title }}</h1>
            <h6 class="form-control-sm" style="color: gray;"><strong>Category</strong> : <span>{{ this.paperDetails.category }}</span></h6>
            <h6 class="form-control-sm" style="color: gray;"><strong>Date</strong> : <span>{{ this.paperDetails.created_at.split("T")[0] }}</span></h6>
            <div class="d-flex align-content-center justify-content-center py-5" v-if="this.paperDetails.image!=='https://ijire.com/blogImage/' && this.paperDetails.image!==undefined|null">

              <img class="mx-auto block" style="max-width: 25rem;" :src="this.paperDetails.image" alt="image">
            </div>
            <article class="" v-html="artical()">
              
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Blogs | International Journal | IJIRE',
      meta: [
        {
          name: 'description',
          // content: this.paperPage?String(this.paperDetails.paper_abstract).split(" ").slice(0,70).join(" ") :'Access IJIRE archives to explore past issues. Browse research articles in Engineering, Technology, and Applied Sciences from previous editions.'
          content: this.paperDetails.meta_description !==undefined ? `${String(this.paperDetails.meta_description).split(".")[0]}.` : 'Access IJIRE archives to explore past issues. Browse research articles in Engineering, Technology, and Applied Sciences from previous editions.'
        },
        {
          name: 'keywords',
          content: this.paperDetails.meta_description !==undefined ? `${String(this.paperDetails.tags)}`:'International Journal, IJIRE, Engineering, Technology, Applied Sciences, Research Articles, Archives'
        },
        {
          property: 'og:title',  // Open Graph title for social sharing
          content: document.title
        },
        {
          property: 'og:url',
          content: window.location.href
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  },
  data() {
    return {
      viewPaper: true,
      paperpage: false,
      paperDetails: {},
      mainPageObjList: [],
      title: "",
    };
  },
  
  methods: {
    openPaperDetails(id) {
      this.$router.push({ path: "blogs", query: { id: id } });
      // console.log(year, volume, issue, name);
      this.viewPaper = false;
      this.paperpage = true;
    },
    downloadPdf(paperURL) {
      window.open(paperURL, "_blank");
    },
    artical(){
      this.paperDetails.description = this.paperDetails.description.replace("\n", "<br>").replace("&bullet;", "<br>&bullet;").replace("&bull;", "<br>&bull;").replace("_", " ");
      return this.paperDetails.description;
    },
    async getBlogData() {
      try {
        const res = await this.api.postData("blogFetch");
        // console.log("list data", await res.data.blogList);
        this.mainPageObjList =  res.data.blogList;
        

      } catch (err) {
        console.error(err);
      }
    },
    async getBlogDetails(paperid) {
      try {
        const res = await this.api.postDataModulePagination("blogDetails", { id: paperid });
        // console.log("conferencedetails", await res.data.blogDetails);
        this.paperDetails = res.data.blogDetails;
        // this.paperDetails.description = this.paperDetails.description.replace("\n", "<br>")

      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    $route: function () {
      if ("id" in this.$route.query) {
        this.getBlogDetails(this.$route.query.id);
        this.paperpage = true;
        this.viewPaper = false;
      } else {
        this.getBlogData();
        this.paperpage = false;
        this.viewPaper = true;
      }
    },
  },
  mounted() {
    // console.log("running on con");
    if ("id" in this.$route.query) {
      this.getBlogDetails(this.$route.query.id);
      this.paperpage = true;
      this.viewPaper = false;
    }
    else {
      this.getBlogData();
      this.paperpage = false;
      this.viewPaper = true;
    }

    // this.getBlogDetails(1);
  },
};

</script>
<style scoped>
.recentPosts {
  border-bottom: 1px dashed black;
}

.blogTitle {
  border-bottom: 1px dashed black;
}
.download-pdf {
  border: 1px solid #72A9DB;
  color: #72A9DB;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.download-pdf:hover {
  color: #fff;
  background-color: #72A9DB;
}

.header-title {
  border-radius: 5px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.paper-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.sub-header-title {
  display: flex;
  justify-content: space-between;
}

.viewArticlebtn {
  border: 1px solid #72A9DB;
  color: #72A9DB;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.viewArticlebtn:hover {
  color: #fff;
  background-color: #72A9DB;
}

.article-header {
  display: flex;
  justify-content: space-between;
}

.main-paper-header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  margin-bottom: 1rem;
}
</style>
