import { render, staticRenderFns } from "./InstructionForAuthorComponent.vue?vue&type=template&id=6771f183&scoped=true&"
import script from "./InstructionForAuthorComponent.vue?vue&type=script&lang=js&"
export * from "./InstructionForAuthorComponent.vue?vue&type=script&lang=js&"
import style0 from "./InstructionForAuthorComponent.vue?vue&type=style&index=0&id=6771f183&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6771f183",
  null
  
)

export default component.exports