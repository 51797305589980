<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div v-if="viewPaper" style="max-width: 1000px; margin: auto;">
          <div v-for="mainPageObj in mainPageObjList" :key="mainPageObj.id" class="main-paper-header p-2 ">
            <div class="article-header justify-content-between row">
              <h4 class="col-8 col-sm-9 col-md-10">{{ mainPageObj.name }}</h4>
              <div class="col-4 col-sm-3 col-md-2">

                <button type="submit"
                  @click="openPaperDetails(mainPageObj.id, mainPageObj.year, mainPageObj.volume, mainPageObj.issue, mainPageObj.name)"
                  class="viewArticlebtn p-2 float-right">
                  View Article
                </button>
              </div>
            </div>
            <div class="col">
              <h6>{{ mainPageObj.title }}</h6>
              <p><b>Organized By : </b>{{ mainPageObj.organised_by }}</p>
              <p><b>Conference Date : </b>{{ mainPageObj.conference_date }}</p>
            </div>
          </div>

        </div>
        <div v-if="!viewPaper && paperpage" class="container" style="max-width: 1000px; margin: auto;">
          <div class="header-title p-2 mb-4  ">
            <h4 style="text-align: center;">{{ this.year }} | Volume {{ this.volume }} - Issue {{ this.issue }} | {{
              this.title }}</h4>
          </div>
          <div v-for="items in paperDetails" :key="items.id" class="paper-container p-2 mb-3 ">
            <div class="row sub-header-title">
              <div class="col-8 col-lg-10">
                <h4>{{ items.title }}</h4>
              </div>

              <div class="col-4 col-lg-2 d-flex justify-content-end">
                <button type="button" class="download-pdf p-2 mb-3" @click="downloadPdf(items.pdf_url)">
                  Download PDF
                </button>
              </div>
            </div>
            <div class="row">
              <p class="col-12"><b>Author: </b>{{ items.author }}</p>
              <p class="col-12"><b>Article Type: </b>{{ items.article_type }}</p>
              <p class="col-12"><b>Pages: </b>{{ items.pages }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import this.api from "../../../services/api"; // Adjust the path based on your structure
// import React24 from "../../../util/conference-react24";

export default {
  data() {
    return {
      viewPaper: true,
      paperpage: false,
      paperDetails: [],
      mainPageObjList: [],
      year: "",
      volume: "",
      issue: "",
      title: "",
    };
  },
  methods: {
    openPaperDetails(id, year, volume, issue, name) {
      this.$router.push({ path: "conference", query: { id: id, year: year, volume: volume, issue: issue, name: name } });
      // console.log(year, volume, issue, name);
      this.year = year
      this.volume = volume
      this.issue = issue
      this.title = name
      this.viewPaper = false;
      this.paperpage = true;
    },
    downloadPdf(paperURL) {
      window.open(paperURL, "_blank");
    },
    getTitle() {
      return this.title;
    },
    getYear() {
      return this.year;
    },
    getVolume() {
      return this.volume;
    },
    getIssue() {
      return this.issue;
    },
    async getConferenceData() {
      try {
        const res = await this.api.postData("conferenceCategoriesFetch");
        //console.log("list data", res.data.Conference_CategoriesList);
        this.mainPageObjList = res.data.Conference_CategoriesList;

      } catch (err) {
        console.error(err);
      }
    },
    async getConferenceDetails(paperid) {
      try {
        const res = await this.api.postDataModulePagination("conferenceFetch", { id: paperid });
        //console.log("conferencedetails", res.data.ConferenceList);
        this.paperDetails = res.data.ConferenceList;

      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    $route: function () {
      if ("id" in this.$route.query) {
        this.getConferenceDetails(this.$route.query.id);
        this.paperpage = true;
        this.viewPaper = false;
      } else {
        this.getConferenceData();
        this.paperpage = false;
        this.viewPaper = true;
      }
    },
  },
  mounted() {
    // console.log("running on con");
    if ("id" in this.$route.query && "year" in this.$route.query && "name" in this.$route.query && "issue" in this.$route.query && "volume" in this.$route.query) {
      this.getConferenceDetails(this.$route.query.id);
      this.year = this.$route.query.year;
      this.volume = this.$route.query.volume;
      this.issue = this.$route.query.issue;
      this.title = this.$route.query.name;
      this.paperpage = true;
      this.viewPaper = false;
    }
    else {
      this.getConferenceData();
      this.paperpage = false;
      this.viewPaper = true;
    }

    // this.getConferenceDetails(1);
  },
};
</script>

<style scoped>
.download-pdf {
  border: 1px solid #72A9DB;
  color: #72A9DB;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.download-pdf:hover {
  color: #fff;
  background-color: #72A9DB;
}

.header-title {
  border-radius: 5px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.paper-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.sub-header-title {
  display: flex;
  justify-content: space-between;
}

.viewArticlebtn {
  border: 1px solid #72A9DB;
  color: #72A9DB;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.viewArticlebtn:hover {
  color: #fff;
  background-color: #72A9DB;
}

.article-header {
  display: flex;
  justify-content: space-between;
}

.main-paper-header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  
}
</style>
